import { TemplateIcon } from '@heroicons/react/outline'
import React, { useMemo, useRef, useState } from 'react'
import { ToolTipHoverContainer, ToolTipTop } from '../../../../../../../components/ToolTips'
import FadeIn from '../../../../../../../components/transitions/FadeIn'
import useOnClickOutside from '../../../../../../../hooks/useClickOutside'
import { PlusCircleIcon } from '@heroicons/react/outline'
import CreateTemplate from './CreateTemplate'
import { useQuery } from 'react-query'
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../../../../api'
import TemplateItem from './TemplateItem'
import { classNames } from '../../../../../../../helpers/classNames'

const tabs = [
    { name: 'Public', value: 'public' },
    { name: 'Private', value: 'private' },
]

const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR)
const Templates = ({ emailId, toggleAcceptChange, isMaximized }) => {
    const [open, setOpen] = useState(false);
    const [showCreateNewTemplate, setShowCreateNewTemplate] = useState(false)
    const [currentTab, setCurrentTab] = useState('public');
    const ref = useRef()

    useOnClickOutside(ref, () => {
        setOpen(false);
    });

    const { data: templates, isLoading } = useQuery({
        queryFn: bunmailApi.getFn({
            endpoint: 'templates'
        }),
        queryKey: 'email-templates'
    })

    const [query, setQuery] = useState("");
    const filteredTemplates = useMemo(() => {
        if (!templates) return [];

        const filtered = templates.filter(item => {
            const matchesSearch = query === "" ||
                item.title?.toLowerCase().includes(query.toLowerCase()) ||
                JSON.stringify(item?.content)?.includes(query.toLowerCase());

            const matchesTab = currentTab === 'public' ? !item.user_id : !!item.user_id;

            return matchesSearch && matchesTab;
        });

        return filtered;
    }, [query, templates, currentTab]);

    return (
        <div className='relative'>
            <ToolTipHoverContainer>
                <ToolTipTop>
                    Templates
                </ToolTipTop>
                <button onClick={() => setOpen(!open)}
                    className='btn-gray-clear-rounded'
                >
                    <TemplateIcon className='h-6 w-6 text-gray-600' />
                </button>
            </ToolTipHoverContainer>

            <FadeIn isShowing={open}>
                <div
                    ref={ref}
                    className={classNames(isMaximized ? 'left-0' : 'right-0 ', "absolute z-10 mb-10 w-[700px] p-4 bottom-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none")}
                >
                    <div className="">
                        <div className="flex justify-between border-b pb-1 mb-2">
                            <h3 className="text-sm font-semibold">Email Templates</h3>
                            <ToolTipHoverContainer>
                                <ToolTipTop>
                                    Create template from this email
                                </ToolTipTop>
                                <button
                                    onClick={() => setShowCreateNewTemplate(true)}
                                    className="">
                                    <PlusCircleIcon className='h-5 w-5 text-gray-500 hover:bg-gray-100 rounded-full' />
                                </button>
                            </ToolTipHoverContainer>
                        </div>
                    </div>
                    <CreateTemplate open={showCreateNewTemplate} setOpen={setShowCreateNewTemplate} emailId={emailId} />

                    {!showCreateNewTemplate && (
                        <>
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <button
                                            key={tab.value}
                                            onClick={() => setCurrentTab(tab.value)}
                                            className={classNames(
                                                tab.value === currentTab
                                                    ? 'border-blue-500 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                            )}
                                        >
                                            {tab.name}
                                        </button>
                                    ))}
                                </nav>
                            </div>

                            <input
                                type="text"
                                placeholder="Search templates..."
                                className="border-2 border-gray-300 rounded-md py-2 px-4 w-full focus:outline-none focus:border-blue-500 mt-4"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)} />

                            <ul className="py-1 min-h-[200px] max-h-[800px] overflow-auto custom-scrollbar-thick custom-scrollbar-gray w-full mt-2">
                                {filteredTemplates && filteredTemplates.map((template) => (
                                    <li key={template.id}>
                                        <TemplateItem template={template} emailId={emailId} toggleAcceptChange={toggleAcceptChange} />
                                    </li>
                                ))}
                                {filteredTemplates.length === 0 && (
                                    <li className="text-center py-4 text-gray-500">
                                        No {currentTab} templates found
                                    </li>
                                )}
                            </ul>
                        </>
                    )}
                </div>
            </FadeIn>
        </div>
    )
}

export default Templates