import { useMemo, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import ApiRequest from '../../../../../api/ApiRequest'
import { API_AUTH_TYPES, API_ROUTES } from '../../../../../api/apiConstants'
import { useQuery } from 'react-query'
import { PlusCircleIcon } from '@heroicons/react/outline'
import CreatePrompt from './CreatePrompt'
import FadeIn from '../../../../transitions/FadeIn'
import useOnClickOutside from '../../../../../hooks/useClickOutside'
import PromptItem from './PromptItem'
import { classNames } from '../../../../misc/styling'

const parabotApi = new ApiRequest(API_ROUTES.PARABOT, API_AUTH_TYPES.ADVISOR)

const tabs = [
    { name: 'Public', value: 'public' },
    { name: 'Private', value: 'private' },
]

export default function PromptList({ handleSendPrompt = () => { } }) {
    const [showCreateNewPrompt, setShowCreateNewPrompt] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('public');
    const { data: templates, isLoading } = useQuery({
        queryKey: ['parabot-prompt-templates'],
        queryFn: parabotApi.getFn({
            endpoint: 'index_prompt_templates',
        }),
    })
    const ref = useRef()

    useOnClickOutside(ref, () => {
        setIsOpen(false);
    });
    const [query, setQuery] = useState("");

    const filteredTemplates = useMemo(() => {
        if (!templates) return [];

        const filtered = templates.filter(item => {
            const matchesSearch = query === "" ||
                item.title?.toLowerCase().includes(query.toLowerCase()) ||
                item.prompt?.toLowerCase().includes(query.toLowerCase());

            const matchesTab = currentTab === 'public' ? !item.user_id : !!item.user_id;

            return matchesSearch && matchesTab;
        });

        return filtered;
    }, [query, templates, currentTab]);

    if (isLoading) return <div></div>;
    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center space-x-1 rounded-full bg-white py-1 px-2.5 text-sm font-semibold w-max text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Prompts
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>

            <FadeIn
                isShowing={isOpen}
            >
                <div ref={ref}
                    className="absolute left-0 z-10 mb-10 w-[450px] p-4 bottom-0 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="">
                        <div className="flex justify-between border-b pb-1 mb-2">
                            <h3 className="text-sm font-semibold">Prompt Templates</h3>
                            <button
                                onClick={() => setShowCreateNewPrompt(true)}
                                className=""><PlusCircleIcon className='h-5 w-5 text-gray-500 hover:bg-gray-100 rounded-full' /></button>
                        </div>
                        <CreatePrompt isOpen={showCreateNewPrompt} setIsOpen={setShowCreateNewPrompt} />
                    </div>

                    {!showCreateNewPrompt && (
                        <>
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <button
                                            key={tab.value}
                                            onClick={() => setCurrentTab(tab.value)}
                                            className={classNames(
                                                tab.value === currentTab
                                                    ? 'border-blue-500 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                            )}
                                        >
                                            {tab.name}
                                        </button>
                                    ))}
                                </nav>
                            </div>

                            <input
                                type="text"
                                placeholder="Search prompts..."
                                className="border-2 border-gray-300 rounded-md py-2 px-4 w-full focus:outline-none focus:border-blue-500 mt-4"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)} />

                            <ul className="py-1 max-h-[500px] overflow-auto custom-scrollbar-thick custom-scrollbar-gray mt-2">
                                {filteredTemplates && filteredTemplates.map((template) => (
                                    <li key={template.id}>
                                        <PromptItem template={template} handleSendPrompt={handleSendPrompt} />
                                    </li>
                                ))}
                                {filteredTemplates.length === 0 && (
                                    <li className="text-center py-4 text-gray-500">
                                        No {currentTab} prompts found
                                    </li>
                                )}
                            </ul>
                        </>
                    )}
                </div>
            </FadeIn>
        </div>
    )
}
